<template>
  <v-sheet
    rounded="lg"
    class="pt-2"
    min-height="calc(100vh - 68px)"
    color="accent"
    id="scrolling-techniques-7"
  >
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb></template>

    <v-row style="padding: 0 2%">
      <v-col cols="2">
        <BackActionBar @goback="goback"></BackActionBar>
      </v-col>
      <v-col cols="8">
        <ContextualActionBar
          title="Piano Operativo - Scenari Predefiniti"
        ></ContextualActionBar>
      </v-col>
    </v-row>
    <v-divider dark></v-divider>
    <v-container>
      <perfect-scrollbar class="d-flex align-center">
        <v-card class="mx-auto my-auto" width=" 50% ">
          <v-overlay :value="displayProgress">
            <v-progress-circular
              :size="40"
              :width="4"
              color="amber"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-radio-group v-model="radioDefault">
              <v-radio
                label="TRIFOGLIO"
                value="trifoglio"
                @click="clearSelected()"
              >
                <template v-slot:label>
                  <v-row align="center" justify="center">
                    <v-col>
                      <strong class="text-subtitle-2"> TRIFOGLIO </strong>
                    </v-col>
                    <v-col cols="8">
                      <v-select
                        outlined
                        v-model="select"
                        :items="itemsTrifoglio"
                        item-text="state"
                        item-value="abbr"
                        label="Seleziona"
                        hide-details="true"
                        :disabled="radioDefault !== 'trifoglio' ? true : false"
                        @change="setBodyData(select)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
              <v-radio label="CAGE" value="cage" @click="clearSelected()">
                <template v-slot:label>
                  <v-row align="center" justify="center">
                    <v-col>
                      <strong class="text-subtitle-2"> CAGE </strong>
                    </v-col>
                    <v-col cols="8">
                      <v-select
                        outlined
                        v-model="select"
                        :items="itemsCage"
                        item-text="state"
                        label="Seleziona"
                        hide-details="true"
                        :disabled="radioDefault !== 'cage' ? true : false"
                        @change="setBodyData(select)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
              <!--  <v-radio label="BS/IS" value="bsis">
                <template v-slot:label>
                  <v-row align="center" justify="center">
                    <v-col>
                      <strong class="text-subtitle-2"> BS/IS </strong>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        outlined
                        multiple
                        v-model="select"
                        :items="itemsBsIs"
                        item-text="state"
                        label="Seleziona"
                        hide-details="true"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </template>
              </v-radio> -->
              <v-radio
                label="Componenti"
                value="componenti"
                @click="clearSelected()"
              >
                <template v-slot:label>
                  <v-row align="center" justify="center">
                    <v-col>
                      <strong class="text-subtitle-2"> Componenti </strong>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        outlined
                        v-model="select"
                        :items="itemsComponents"
                        item-text="state"
                        label="Seleziona"
                        hide-details="true"
                        :disabled="radioDefault !== 'componenti' ? true : false"
                        @change="setBodyData(select)"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions class="d-flex justify-center mb-2">
            <v-btn
              color="secondary"
              depressed
              @click="goToPlan()"
              :disabled="select == '' ? true : false"
            >
              Apri Piano
            </v-btn>
            <v-btn
              color="secondary"
              depressed
              @click="esport()"
              :disabled="select == '' ? true : false"
            >
              Esporta Piano
            </v-btn>
          </v-card-actions>
        </v-card>
      </perfect-scrollbar>
    </v-container>
    <ModalAlert
      @open-dialog-alert="updateDialogAlert"
      :dialogAlert="openDialogAlert"
      :alert="infoAlerts"
      :title="alertTitle"
    ></ModalAlert>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import BackActionBar from "../components/core/BackActionBar.vue";
import download from "downloadjs";
import planIsActive from "@/mixins/planIsActive.js";
import ModalAlert from "@/components/ModalAlert.vue";
export default {
  name: "OperatingCards",
  components: { ContextualActionBar, ModalAlert, BackActionBar },
  mixins: [connectionMixin, planIsActive],
  data() {
    return {
      displayProgress: false,
      radioDefault: "trifoglio",
      select: [],
      bodyData: { trifoglio: "", cage: "", componenti: "" },
      itemsTrifoglio: [],
      itemsCage: [],
      itemsBsIs: [],
      itemsComponents: [],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Piano operativo",
          to: "/operationalplan",
        },

        {
          text: "Scenari predefiniti",
          href: "#",
        },
      ],
      idPlan: "",
      infoAlerts: [],
      openDialogAlert: false,
      alertTitle: "SCENARIO PREDEFINITO",
    };
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.getTrifoglioValues();
          this.getCageValues();
          this.getStorageValues();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    setBodyData(sel) {
      switch (this.radioDefault) {
        case "trifoglio":
          this.bodyData = { trifoglio: sel, cage: "", componenti: "" };
          break;
        case "cage":
          this.bodyData = { trifoglio: "", cage: sel, componenti: "" };
          break;
        case "componenti":
          this.bodyData = { trifoglio: "", cage: "", componenti: sel };
          break;
        default:
          this.bodyData = { trifoglio: "", cage: "", componenti: "" };
      }
    },
    updateDialogAlert(value) {
      this.openDialogAlert = value;
      if (value == false) {
        this.$router.push({
          name: "OpPlanImpactId",
          params: {
            planId: this.idPlan,
          },
        });
      }
    },
    goback() {
      this.$router.push("/operationalplan");
    },
    goToPlan: function () {
      this.displayProgress = true;
      this.$http
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_OSAVEPLAN_FROM_SCENARIO"),
          this.bodyData,
          this.opts
        )
        .then((response) => {
          this.activate(false);
          localStorage.setItem("dataPlan", JSON.stringify(response.data));
          localStorage.setItem("planIsActive", false);
          this.displayProgress = false;
          this.idPlan = response.data.idPlan;
          if (response.data.report.message) {
            this.infoAlerts.push(response.data.report.message);
            this.updateDialogAlert(true);
          }
        })
        .catch((error) => {
          this.displayProgress = false;
          this.disableAlert = true;
          console.log(error);
          alert("Si prega di ricaricare la pagina");
        });
    },
    esport() {
      this.displayProgress = true;
      this.$http
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_OSAVEPLAN_FROM_SCENARIO"),
          this.bodyData,
          this.opts
        )
        .then((response) => {
          this.planExport(response.data.idPlan);
        })
        .catch((error) => {
          this.displayProgress = false;
          console.log(error);
          alert("Si prega di ricaricare la pagina");
        });
    },
    async planExport(id) {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_PLAN") +
            "/" +
            id,
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];

        download(response.data, "export-piano.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    clearSelected() {
      this.select = "";
    },
    getTrifoglioValues: function () {
      this.displayProgress = true;
      this.$http
        .get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_TRIFOGLIO_VALUES"),
          this.opts
        )
        .then((response) => {
          this.itemsTrifoglio = response.data.values;
          this.displayProgress = false;
        })
        .catch((error) => {
          this.displayProgress = false;
          console.log(error);
          alert("Si prega di ricaricare la pagina");
        });
    },
    getCageValues: function () {
      this.displayProgress = true;
      this.$http
        .get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_CAGE_VALUES"),
          this.opts
        )
        .then((response) => {
          this.itemsCage = response.data.values;
          this.displayProgress = false;
        })
        .catch((error) => {
          this.displayProgress = false;
          console.log(error);
          alert("Si prega di ricaricare la pagina");
        });
    },
    getStorageValues: function () {
      this.displayProgress = true;
      this.$http
        .get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_STORAGE_VALUES"),
          this.opts
        )
        .then((response) => {
          this.itemsComponents = response.data.values;
          this.displayProgress = false;
        })
        .catch((error) => {
          this.displayProgress = false;
          console.log(error);
          alert("Si prega di ricaricare la pagina");
        });
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 157px);
}
</style>
